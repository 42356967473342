import Footer from "../Layouts/Footer/Footer";
import Navbar from "../Layouts/Navbar/Navbar";
import heroobj from "../../assets/Images/heroobj.svg";
import obj from "../../assets/Images/obj.svg";

const TermsAndCond = () => {
  return (
    <>
      <Navbar />
      <img src={heroobj} alt={heroobj} className="heroobj bounce fixed top-[20px] right-0 opacity-10" />
      <img src={obj} alt={obj} className="heroobj fixed bounce bottom-10 -x`z-10 left-0 opacity-10" />
      <div className="lg:px-20 px-5  md:text-left flex flex-col gap-5 my-12">
        <h1>Terms of service</h1>
        <p>
          Welcome to JelloSite ("Company", "we", "our", "us"). By using our website and services, you ("User", "Customer", "Site Owner") agree to
          comply with and be bound by the following terms and conditions. Please read them carefully.
        </p>
        <div className="flex flex-col gap-5">
          <h3>Service Overview</h3>
          <div className="flex flex-col gap-3">
            <p>
              JelloSite provides a platform that allows users to create and host their websites using pre-made templates. All websites created using
              our service are hosted as subdomains on jellosite.store.
            </p>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Account Terms</h3>
            <div className="flex flex-col gap-3">
              <p>
                You are responsible for your JelloSite Account ("Account"), the content and materials ("Materials") you upload to the JelloSite
                Service, and the operation of your JelloSite Store. If you violate these Terms of Service, we may suspend or cancel your access to our
                Services. We will communicate with you via the email address provided at registration ("Primary Email Address").
              </p>
              <p>
                To access and use our Services, you must register for an Account. During registration, you must provide your full legal name, business
                address, phone number, valid email address, and any other information required. JelloSite may reject your application for an Account
                or cancel an existing Account at our sole discretion.
              </p>
              <p>
                To open an Account, you must be at least the older of: (i) 18 years of age, or (ii) the age of majority in your jurisdiction. You
                confirm that you are using JelloSite Services solely for the purposes of business activity and not for personal, household, or family
                purposes.
              </p>
              <p>
                You must monitor your Primary Email Address, which must be capable of both sending and receiving messages. All email communications
                with JelloSite can only be authenticated if they come from your Primary Email Address.
              </p>
              <p>
                You are responsible for keeping your password secure. JelloSite is not liable for any loss or damage resulting from your failure to
                maintain the security of your Account and password. We may request additional security measures at any time and reserve the right to
                modify these requirements as needed.
              </p>
              <p>
                Technical support for our Services is only provided to JelloSite Users. Questions regarding the Terms of Service should be directed to
                {" "}<a href="mailto:support@jellosite.com">support@jellosite.com</a>.
              </p>
              <p>
                You agree not to reproduce, duplicate, copy, sell, resell, or exploit any part of the Service, use of the Service, or access to the
                Service without express written permission from JelloSite.
              </p>
              <p>
                You agree not to bypass, circumvent, or work around any technical limitations of the Services, including but not limited to processing
                orders outside of JelloSite's systems, using tools to enable features or functionalities that are otherwise disabled, or reverse
                engineering the Services.
              </p>
              <p>
                You agree not to use any robot, spider, scraper, or other automated means to access the Services or monitor any material or
                information within the Services.
              </p>
              <p>
                You understand that your Materials may be transferred unencrypted and involve (a) transmissions over various networks, and (b) changes
                to conform and adapt to technical requirements of connecting networks or devices. "Materials" refer to your trademarks, copyrighted
                content, any products or services sold through the Services (including descriptions and prices), and any photos, images, videos,
                graphics, written content, audio files, code, information, or other data provided by you or your affiliates to JelloSite or its
                affiliates.
              </p>{" "}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Jellosite Rights</h3>
            <div className="flex flex-col gap-3">
              <p>
                Jellosite has the right to determine to whom we make our Services available and may modify or discontinue them at any time without
                notice. We also reserve the right to refuse or remove any content ("Materials") from any part of the Services, including your website
                ("Store").
              </p>
              <p>
                We may provide our Services to your competitors and make no promise of exclusivity. However, we will never share your confidential
                information with your competitors while providing similar services. JelloSite employees or contractors may also use our Services and,
                while they may compete with you, they will not use your confidential information for their benefit.
              </p>
              <p>
                In the event of a dispute over the ownership of a JelloSite account, we reserve the right to determine, at our sole discretion, the
                rightful Account Owner. If required, we may request documentation to confirm ownership, such as a scanned copy of a business license,
                government-issued photo ID, or other identifying documents. If we cannot determine the rightful owner, JelloSite reserves the right to
                freeze or suspend the Account until the issue is resolved between the disputing parties.
              </p>
              <p>
                We do not pre-screen Materials, but it is within our sole discretion to refuse or remove any Materials from the Services, including if
                we determine that the goods or services you offer, or the content uploaded or posted to the Services, violate these Terms of Service
                or Legal activities.
              </p>
              <p>
                Verbal or written abuse of any kind (including threats or abuse towards any JelloSite employee, member, or officer) will result in
                immediate termination of your Account.
              </p>
              <p>
                The Services provided by JelloSite include a range of features and functionalities. Not all features will be available to all users at
                all times, and we are under no obligation to make any specific feature or service available in any jurisdiction.
              </p>
              <p>
                Except where explicitly prohibited by these Terms of Service or applicable law, we reserve the right to modify the Services or any
                part thereof for any reason, at any time, and without notice.
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <h3>User Responsibilities</h3>
            <div className="flex flex-col gap-3">
              <p>
                You, as the Site Owner, are solely responsible for all products, services, or content that you post, upload, or distribute through
                your website.
              </p>
              <p>We are not responsible for, nor do we endorse, any products or services provided by Site Owners using our platform.</p>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <h3>Compliance with Legal Authorities</h3>
            <div className="flex flex-col gap-3">
              <p>
                We may share information about Site Owners, their activities, and their websites as required by law, government request, or to comply
                with a legal process.
              </p>
              <p>
                You agree that any illegal activity conducted via your website may result in the suspension or termination of your account, and we
                will cooperate with legal authorities as necessary.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Payment Handling</h3>
            <div className="flex flex-col gap-3">
              <p>
                Payments for our subscription plans are processed through a third-party service, <a href="https://paystack.com/">Paystack</a>.
                JelloSite does not collect or store credit card information. All payment information, such as credit card data, is securely handled by
                Paystack in accordance with their policies and procedures.
              </p>
              <p>
                By using Jellosite as a service, you agree to <a href="https://paystack.com/terms">Paystack's terms and conditions</a> for payment
                handling.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Subscription and Pricing</h3>
            <div className="flex flex-col gap-3">
              <p>
                Our services are offered on a subscription-based model, where Site Owners pay a recurring fee for continued access and usage of our
                platform.
              </p>
              <p>
                The subscription fee is determined by the plan you choose and the features included in that plan. You may upgrade or downgrade your
                plan at any time. Changes will take effect immediately and will be reflected in your next billing cycle.
                <br />
                <br />
                The plan can be canceled at any time. Cancellation will take effect immediately, and you will not be billed for the next billing
                cycle.
                <br />
                <br />
                The plan price can be increased or decreased at anytime by Jellosite. You will be notified of any changes to the plan price before the
                changes take effect on your next billing cycle.
              </p>
              <p>
                If not on a free trial or special offers, You agree to pay the subscription fees as outlined on the{" "}
                <a href="/#pricing">Jellosite Pricing page</a>. Non-payment may result in suspension or termination of services.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Disclaimer of Liability</h3>
            <div className="flex flex-col gap-3">
              <p>
                JelloSite is not liable for any claims, damages, or disputes arising out of your use of products, services, or content available on
                your website.
              </p>
              <p>
                We do not warrant or guarantee that the platform is free of errors, and we are not liable for any losses or damages caused by errors,
                system failures, or unauthorized access.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Termination</h3>
            <div className="flex flex-col gap-3">
              <p>
                We reserve the right to terminate your access to our platform at any time, for any reason, including but not limited to a breach of
                these Terms and Conditions or illegal activities.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Amendments</h3>
            <div className="flex flex-col gap-3">
              <p>
                We may modify these Terms and Conditions at any time. Changes will be communicated to Users via their primary email (The email used at
                the point of registration). Continued use of our platform indicates acceptance of the updated terms.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Governing Law</h3>
            <div className="flex flex-col gap-3">
              <p>
                These Terms and Conditions are governed by the laws of The Federal Republic of Nigeria. Any disputes shall be resolved under the
                jurisdiction of The Federal Republic of Nigeria.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Contact Information</h3>
            <div className="flex flex-col gap-3">
              <p>For questions or concerns about these Terms and Conditions, you may contact us at <a href="legal@jellosite.com ">legal@jellosite.com</a></p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h3>Acceptance</h3>
            <div className="flex flex-col gap-3">
              <p>By using JelloSite, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
            </div>
          </div>
        </div>
        Last Updated: September 28, 2024
        <div>
          <p>Jellosite</p>
          <p>Abuja, Nigeria</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndCond;
